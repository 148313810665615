import React, { memo, useEffect, useRef, useState } from "react";
import classes from "./UsedCard.module.scss";
import { Link, useLocation } from "react-router-dom";
import TagList from "../tag-list/TagList";
import { useDispatch, useSelector } from "react-redux";
import {
  removeModelFromPanel,
  usedModelsActions,
} from "../../store/usedModels";
import { promptActions } from "../../store/prompt";
import ActivationTag from "../activation-tag/ActivationTag";
import Arrow from "../ui/Arrow";
import Image from "../ui/image/Image";
import { modelActions } from "../../store/model";
import { motion } from "framer-motion";
import {
  ANIMATIONS_FM_HOVER_SCALE,
  ANIMATIONS_FM_TAP_SCALE,
  SETTINGS_IMAGE_PREVIEW_WIDTH_BIG,
  SETTINGS_IMAGE_PREVIEW_WIDTH_MEDIUM,
} from "../../variables/constants";
const taglistItemHeight = 68;

const UsedCard = memo(({ previewData, fullView, layoutId }) => {
  const [tagsIsOpen, setTagsIsOpen] = useState(false);
  const [tagsHeight, setTagsHeight] = useState(null);
  const [taglistHeight, setTaglistHeight] = useState(null);
  const [cardLayuotId, setcardLayuotId] = useState(previewData.id);
  const [cardIsHidden, setCardIsHidden] = useState(false);
  const isNsfwMode = useSelector((state) => state.model.nsfwMode);
  // const model = useSelector((state) => state.model.model);
  const fullCardView = useSelector((state) => state.used.fullCardView);
  const dispatch = useDispatch();
  const tagsRef = useRef();
  const tagsListRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      setCardIsHidden(true);
    }, 1000);
    // setcardLayuotId(Math.random());
  }, []);

  useEffect(() => {
    if (tagsRef?.current?.clientHeight && fullCardView)
      setTaglistHeight(tagsRef?.current?.clientHeight);
    if (taglistHeight === taglistItemHeight) setTagsHeight(taglistItemHeight);
  }, [
    previewData,
    taglistHeight,
    // taglistItemHeight,
    tagsRef?.current?.clientHeight,
    fullCardView,
  ]);

  const openTagsHandler = () => {
    setTagsIsOpen((prev) => {
      if (prev) {
        setTagsHeight(null);
      } else {
        setTagsHeight(taglistHeight);
      }
      return !prev;
    });
  };

  const closeCardHandler = () => {
    dispatch(removeModelFromPanel(previewData.id));
    // dispatch(promptActions.removeTag(previewData.mainTag));
  };

  const closePanelHandler = () => {
    // if (previewData?.id !== model?.id) {
    //   dispatch(modelActions.resetModelData());
    // }
    if (document.body.offsetWidth < 1024) {
      dispatch(usedModelsActions.panelState(false));
    }
  };

  return (
    <motion.div
      layout
      layoutId={layoutId || null}
      initial={{ opacity: 0, y: 30 }}
      animate={
        !layoutId ? { opacity: [0, 0, 0, 1], y: 0 } : { opacity: 1, y: 0 }
      }
      // animate={!layoutId ? { opacity: [0, 0, 1], y: 0 } : FM_ANIMTION_SLIDEIN}
      exit={{ opacity: 0, y: 30 }}
      // layoutRoot
      // exit={FM_ANIMTION_SLIDEIN_INITIAL}
      id={previewData.id}
      className={`${classes.card} card ${
        layoutId ? classes["card--motion"] : ""
      } 
      ${cardIsHidden ? classes["card--hidden"] : ""} 
      `}
    >
      <div className={classes.head}>
        <Link
          to={`/models/${previewData.id}`}
          state={{ versionId: previewData?.activeVersionId || null }}
          className={classes.link}
          onClick={closePanelHandler}
        >
          <Image
            src={
              isNsfwMode
                ? previewData.nsfwPreviewImgUrl ||
                  previewData.customPreviewImgUrl ||
                  previewData.imgUrl
                : previewData.customPreviewImgUrl || previewData.imgUrl
            }
            alt="Preview"
            imageWidth={SETTINGS_IMAGE_PREVIEW_WIDTH_BIG}
            className={classes.image}
          />
        </Link>
        <div className={classes.info}>
          <div className={classes["title-container"]}>
            <Link
              to={`/models/${previewData.id}`}
              state={{ versionId: previewData?.activeVersionId || null }}
              className={classes.link}
              onClick={closePanelHandler}
            >
              <h4
                className={classes.title}
                title={previewData.name || previewData.title}
              >
                {previewData.name || previewData.title}
              </h4>
            </Link>
          </div>
          {previewData?.versionName && (
            <div className={classes["version-name"]}>
              {previewData?.versionName}
            </div>
          )}
          <div className={classes["base-info"]}>
            <span
              className={`${classes["base-info__item"]} ${classes["base-info__item--type"]}`}
            >
              {previewData.type || previewData.modelType}
            </span>
            {previewData?.baseModel && (
              <span className={classes["base-info__item"]}>
                {previewData.baseModel}
              </span>
            )}
          </div>
        </div>
        <button className={classes["btn__close"]} onClick={closeCardHandler}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      {fullView &&
        (previewData?.minWeight ||
          previewData?.mainTag ||
          !!previewData?.tags?.length) && (
          <div className={`${fullView ? classes.content : ""}`}>
            {(!!previewData?.minWeight || !!previewData?.weight) && (
              <div className={classes["weight"]}>
                <span>Weight:</span>
                <span className={classes["weight__value"]}>
                  {previewData?.minWeight
                    ? `${previewData?.minWeight?.toFixed(1)} - 
                    ${previewData?.maxWeight?.toFixed(1)}`
                    : ""}{" "}
                  {previewData?.weight
                    ? `(${previewData?.weight?.toFixed(1)})`
                    : ""}
                </span>
              </div>
            )}
            {!!previewData.mainTag && fullView && (
              <div className={classes["main-tag"]}>
                <ActivationTag
                  tag={previewData.mainTag}
                  modelData={previewData}
                  strength={previewData.weight}
                />
              </div>
            )}

            <div className={classes["tags-container"]}>
              {!!previewData?.tags?.length && (
                <>
                  <div
                    className={`${classes.tags} ${
                      tagsIsOpen ? classes["tags--open"] : ""
                    }`}
                    style={tagsHeight ? { maxHeight: `${tagsHeight}px` } : {}}
                  >
                    <div
                      ref={tagsRef}
                      className={`${classes["tags__list"]} ${
                        taglistHeight > taglistItemHeight ? classes.shadow : ""
                      }`}
                    >
                      <TagList
                        name="Trigger words"
                        ref={tagsListRef}
                        tags={previewData.tags}
                        className={classes["tag-list"]}
                        promptType="positive"
                      />
                    </div>
                  </div>
                  {/* {taglistHeight > taglistItemHeight && ( */}
                  {taglistHeight > taglistItemHeight && (
                    <button
                      className={`${classes["tags__btn"]} ${
                        !tagsIsOpen ? classes["tags__btn--shadow"] : ""
                      }`}
                      onClick={openTagsHandler}
                    >
                      <Arrow direction={tagsIsOpen ? "up" : "down"} />
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        )}
    </motion.div>
  );
});

export default UsedCard;
