import React, { useCallback, useEffect, useState } from "react";
import classes from "./CarouselImage.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteImgPost,
  setPreviewImg,
  setTagSetPreviewImg,
} from "../../../store/model";
import ButttonTertiary from "../../ui/ButtonTertiary";
import Modal from "../../ui/Modal";
import DeleteRequest from "../../ui/DeleteRequest";
import ButtonAdd from "../../ui/ButtonSquareAdd";
import ImageSvg from "../../../assets/ImageSvg";
import DotsSvg from "../../../assets/DotsSvg";
import { AnimatePresence, motion } from "framer-motion";
import {
  ANIMATIONS_FM_ZOOM_IN,
  ANIMATIONS_FM_ZOOM_IN_INITIAL,
  SETTINGS_IMAGE_PREVIEW_WIDTH_BIG,
} from "../../../variables/constants";
import SetTagSetPreview from "../set-tagset-preview/SetTagSetPreview";
import { transformSrcPreview } from "../../../utils/generalUtils";

const CarouselImage = ({
  id,
  src,
  alt,
  onClick,
  onDelete,
  dataset,
  postId,
  versionId,
  saved,
  nsfw,
  imageData,
  onOpen,
  active,
  side,
  imageWidth,
}) => {
  const [imgIsLoading, setImgIsLoading] = useState(false);
  const [imgIsLoaded, setImgIsLoaded] = useState(false);
  const [imgIsSaved, setImgIsSaved] = useState(!!saved);
  const [deleteRequestIsOpen, setDeleteRequestIsOpen] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [imgSrc, setImgSrc] = useState("#");
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [tagSetMenuIsOpen, settagSetMenuIsOpen] = useState(false);
  const [curTagSetVersionId, setCurTagSetVersionId] = useState("tsv-def");
  const dispatch = useDispatch();
  const model = useSelector((state) => state.model.model);
  const curVersion = useSelector((state) => state.model.curVersion);
  const nsfwMode = useSelector((state) => state.model.nsfwMode);

  useEffect(() => {
    if (src && !imgIsLoaded && !imgError) {
      const previewSrc = transformSrcPreview(
        src,
        SETTINGS_IMAGE_PREVIEW_WIDTH_BIG
      );
      setImgSrc(previewSrc);
      setImgIsLoading(true);
    }
  }, [src, imgIsLoaded, imgError]);

  const imgLoadHandler = () => {
    setImgIsLoading(false);
    setImgIsLoaded(true);
  };

  const imgErrorHandler = () => {
    setImgError(true);
    setImgIsLoading(false);
  };

  const setPreviwImgHandler = (e) => {
    dispatch(setPreviewImg(src, false));
    setMenuIsOpen(false);
  };
  const setNsfwPreviwImgHandler = (e) => {
    dispatch(setPreviewImg(src, true));
    setMenuIsOpen(false);
  };

  const openMenuHandler = () => {
    if (!!model?.modelVersionsCustomData[curVersion.id]?.tagSetsData?.length) {
      setCurTagSetVersionId(`${curVersion.id}`);
    }
    setMenuIsOpen((prevState) => !prevState);
  };

  const openTagSetMenuHandler = () => {
    settagSetMenuIsOpen(true);
    setMenuIsOpen(false);
  };

  const closeTagSetMenuHandler = () => {
    settagSetMenuIsOpen(false);
  };

  const deleteImgPostHandler = () => {
    const imgPostId = postId[0].postId;
    const postData = model?.savedImages[versionId]?.find(
      (post) => post.postId === imgPostId
    );

    dispatch(deleteImgPost(versionId, imgPostId, postData));
    setDeleteRequestIsOpen(false);
    setMenuIsOpen(false);
    setImgIsSaved(false);
  };

  const showDeleteReqeustHandler = (e) => {
    onDelete();
  };

  const closeDeleteReqeustHandler = () => {
    setDeleteRequestIsOpen(false);
  };

  const closeMenuHandler = useCallback((e) => {
    if (!e.target.closest(`.${classes.menu}`)) setMenuIsOpen(false);
  }, []);

  const openFullViewHandler = () => {
    onOpen(true);
  };

  useEffect(() => {
    if (menuIsOpen) {
      document.removeEventListener("click", closeMenuHandler);
      document.addEventListener("click", closeMenuHandler);
    } else {
      document.removeEventListener("click", closeMenuHandler);
    }

    return () => {
      document.removeEventListener("click", closeMenuHandler);
    };
  }, [menuIsOpen, closeMenuHandler]);

  return (
    <motion.div
      // layoutId={id}
      className={`${classes.container} ${
        active && !imgIsLoading && !imgError && imgSrc !== "#"
          ? classes["container--shadow"]
          : ""
      }`}
      style={{ width: imageWidth }}
    >
      {imgIsLoading && <div className={classes.loading}>Loading...</div>}
      {imgError && (
        <div
          className={classes.placeholder}
          onClick={onClick}
          data-position={dataset}
        ></div>
      )}
      <ImageSvg className={classes["image-svg"]} />
      {!imgIsLoading && !side && imgSrc !== "#" && (
        <>
          <div className={classes.menu}>
            <ButttonTertiary
              type="button"
              className={classes["menu-btn"]}
              onClick={openMenuHandler}
              title="Image settings"
            >
              <DotsSvg />
            </ButttonTertiary>
            <AnimatePresence>
              {menuIsOpen && (
                <motion.menu
                  initial={ANIMATIONS_FM_ZOOM_IN_INITIAL}
                  animate={ANIMATIONS_FM_ZOOM_IN}
                  exit={ANIMATIONS_FM_ZOOM_IN_INITIAL}
                  className={classes["menu__list"]}
                >
                  <li
                    className={classes["menu__item"]}
                    onClick={setPreviwImgHandler}
                  >
                    Set as preview
                  </li>
                  <li
                    className={classes["menu__item"]}
                    onClick={openTagSetMenuHandler}
                  >
                    Set as tag set preview
                  </li>
                  {nsfwMode && (
                    <li
                      className={classes["menu__item"]}
                      onClick={setNsfwPreviwImgHandler}
                    >
                      Set as NSFW preview
                    </li>
                  )}
                  {imgIsSaved && (
                    <li
                      className={`${classes["menu__item"]} ${classes["menu__item--del"]}`}
                      onClick={showDeleteReqeustHandler}
                    >
                      Delete
                    </li>
                  )}
                </motion.menu>
              )}
            </AnimatePresence>
          </div>
          <ButtonAdd
            className={classes["btn-add"]}
            previewData={imageData}
            type="image"
          />
        </>
      )}
      {active && !imgIsLoading && !imgError && imgSrc !== "#" && (
        <span className={classes["btn-full"]} onClick={openFullViewHandler}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM10.5 7.5v6m3-3h-6"
            />
          </svg>
        </span>
      )}
      {!imgError && imgSrc !== "#" && (
        <>
          <motion.img
            className={`${classes.image} ${
              imageData?.width - imageData?.height < 0
                ? classes["image--portrait"]
                : ""
            } ${imgIsLoading && !imgIsLoaded ? classes["image--hidden"] : ""} ${
              !nsfwMode && nsfw ? classes["image--nsfw"] : ""
            }`}
            draggable={false}
            onClick={onClick}
            onLoad={imgLoadHandler}
            onError={imgErrorHandler}
            data-position={dataset}
            id={id}
            src={imgSrc}
            alt={alt}
          />
        </>
      )}
      <AnimatePresence>
        {tagSetMenuIsOpen && (
          <Modal
            className={classes["tag-sets__modal"]}
            onClose={closeTagSetMenuHandler}
          >
            <SetTagSetPreview src={src} />
          </Modal>
        )}
        {deleteRequestIsOpen && (
          <DeleteRequest
            message={`Are you sure that you want to delete this post? This action can't
          be reverted`}
            onSubmit={deleteImgPostHandler}
            onClose={closeDeleteReqeustHandler}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default CarouselImage;
